import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import React, { useEffect, useState } from "react";
// import ReactPlayer from "react-player";
import dynamic from "next/dynamic";
import styles from "./VideoGrid.module.css";
import * as prismic from "@prismicio/client";
import { PrismicText } from "@prismicio/react";
import { useCookieConsent } from "@/contexts/CoookieConsentContext";

import {
  Text,
  Box,
  ChakraProvider,
  Container,
  Stack,
  Heading,
  Image,
  Flex,
  Grid,
  Button, // Import Button from Chakra UI
} from "@chakra-ui/react";
import { AppConfig } from "@/utils/AppConfig";

const CookiesDisabled = () => {
  const { consentStatus, setConsentStatus } = useCookieConsent();

  const handleAccept = () => {
    console.log("test");
  };
  return (
    <Container centerContent padding="60px 20px">
      <Text fontSize="lg" textAlign="center" mb="4">
        Consent for all cookies is required to view this content.
      </Text>
      <button
        onClick={() => setConsentStatus("given")}
        className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Accept Cookies
      </button>
    </Container>
  );
};

export default CookiesDisabled;

"use client";

import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import React, { useEffect, useState } from "react";
// import ReactPlayer from "react-player";
import dynamic from "next/dynamic";
import styles from "./VideoGrid.module.css";
import * as prismic from "@prismicio/client";
import { PrismicText } from "@prismicio/react";
import { useCookieConsent } from "@/contexts/CoookieConsentContext";

import {
  Text,
  Box,
  ChakraProvider,
  Container,
  Stack,
  Heading,
  Image,
  Flex,
  Grid,
  Button, // Import Button from Chakra UI
} from "@chakra-ui/react";
import { AppConfig } from "@/utils/AppConfig";
import CookiesDisabled from "@/components/CookiesDisabled/CookiesDisabled";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

// Dynamically import ReactPlayer with SSR disabled
const ReactPlayer = dynamic(() => import("react-player/youtube"), {
  ssr: false,
  loading: () => <p>Loading...</p>,
});

/**
 * Props for `VideoGrid`.
 */
export type VideoGridProps = SliceComponentProps<Content.VideoGridSlice>;

/**
 * Component for "VideoGrid" Slices.
 */
const VideoGrid = ({ slice }: VideoGridProps): JSX.Element => {
  const { consentStatus } = useCookieConsent();

  if (consentStatus !== "given") {
    return <CookiesDisabled />;
  }

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={styles.videoGrid}
    >
      <FadeInWhenVisible>
        <div className="container">
          <Flex textAlign={"center"} justifyContent={"center"}>
            <Image
              opacity={0.3}
              width="100px"
              marginBottom="40px"
              className={styles.logo}
              src="/eca-logo.png"
              alt={`${AppConfig.site_name} Logo`}
            />
          </Flex>
          <Heading
            textStyle="h2"
            as="h2"
            letterSpacing="1px"
            color="brand.white"
            textTransform="uppercase"
            textAlign="center"
            marginBottom="30px"
          >
            <PrismicText field={slice.primary.section_heading} />
          </Heading>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
            {slice.items.map((video) => (
              <div
                key={video.title}
                className="aspect-w-16 aspect-h-9 bg-black rounded overflow-hidden shadow-lg"
              >
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=${video.youtube_video_id}`}
                  controls
                  width="100%"
                  height="100%"
                  className="react-player"
                />
              </div>
            ))}
          </div>
        </div>
      </FadeInWhenVisible>
    </section>
  );
};

export default VideoGrid;
